import { registerApplication, start } from "single-spa";
import { MICROFRONTENDS } from "./microfrontends.map";
import { NamespacesEnum } from "./namespaces.enum";

const defaultFileName = "main.js";

const path = {
  // @ts-ignore
  app: isLocal
    ? "http://localhost:4201/"
    : MICROFRONTENDS.get(NamespacesEnum.APP).s3Path,
  // @ts-ignore
  dmo: isLocal
    ? "http://localhost:4204/"
    : MICROFRONTENDS.get(NamespacesEnum.DMO).s3Path,
  // @ts-ignore
  connect: isLocal
    ? "http://localhost:4202/"
    : MICROFRONTENDS.get(NamespacesEnum.CONNECT).s3Path,
  // @ts-ignore
  office: isLocal
    ? "http://localhost:4203/"
    : MICROFRONTENDS.get(NamespacesEnum.OFFICE).s3Path,
};

// @ts-ignore
const appName = isLocal ? defaultFileName : appFileName;
// @ts-ignore
const dmoName = isLocal ? defaultFileName : dmoFileName;
// @ts-ignore
const coName = isLocal ? defaultFileName : coFileName;
// @ts-ignore
const officeName = isLocal ? defaultFileName : officeFileName;

function isDMO(location: Location): boolean {
  return location.pathname.startsWith(
    MICROFRONTENDS.get(NamespacesEnum.DMO).urlPath
  );
}

function isConnect(location: Location): boolean {
  return location.pathname.startsWith(
    MICROFRONTENDS.get(NamespacesEnum.CONNECT).urlPath
  );
}

function isOffice(location: Location): boolean {
  return location.pathname.startsWith(
    MICROFRONTENDS.get(NamespacesEnum.OFFICE).urlPath
  );
}

function isApp(location: Location): boolean {
  return !isConnect(location) && !isOffice(location) && !isDMO(location);
}

registerApplication({
  name: "fwk-frontend-app",
  app: () => System.import(`${path.app}${appName}`),
  activeWhen: [(location): boolean => isApp(location)],
});

registerApplication({
  name: "fwk-frontend-product-dmo",
  app: () => System.import(`${path.dmo}${dmoName}`),
  activeWhen: [(location): boolean => isDMO(location)],
});

registerApplication({
  name: "fwk-frontend-product-connect",
  app: () => System.import(`${path.connect}${coName}`),
  activeWhen: [(location): boolean => isConnect(location)],
});

registerApplication({
  name: "fwk-frontend-product-office",
  app: () => System.import(`${path.office}${officeName}`),
  activeWhen: [(location): boolean => isOffice(location)],
});

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    // Unregister all SW in case the user already had one
    navigator.serviceWorker
      .getRegistrations()
      .then((registrations) =>
        Promise.all(registrations?.map((register) => register.unregister()))
      );
  });
}

start({
  urlRerouteOnly: true,
});
