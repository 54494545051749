import { MicrofrontendsData } from "./microfrontends.interface";
import { NamespacesEnum } from "./namespaces.enum";

export const MICROFRONTENDS: Map<NamespacesEnum, MicrofrontendsData> = new Map<
  NamespacesEnum,
  MicrofrontendsData
>([
  [NamespacesEnum.CONNECT, { urlPath: "/co", s3Path: "./connect/" }],
  [NamespacesEnum.OFFICE, { urlPath: "/office", s3Path: "./office/" }],
  [NamespacesEnum.DMO, { urlPath: "/dg", s3Path: "./dg/" }],
  [NamespacesEnum.APP, { urlPath: "", s3Path: "./app/" }],
]);
